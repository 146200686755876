const REACT_APP_SERVER_URL = process.env.REACT_APP_SERVER_URL;

// PROJECTS
export const getProject = (projectId: number): Promise<ProjectObject> =>
  fetch(`${REACT_APP_SERVER_URL}/projects/${projectId}`, {
    credentials: "include",
  }).then((response) => {
    if (!response.ok) {
      throw new Error("Failed to fetch project");
    }
    return response.json();
  });

export const getProjects = (): Promise<ProjectObject[]> =>
  fetch(`${REACT_APP_SERVER_URL}/projects`, {
    credentials: "include",
  }).then((response) => {
    if (!response.ok) {
      throw new Error("Failed to fetch project");
    }
    return response.json();
  });

export const createProject = (body: any) =>
  fetch(`${REACT_APP_SERVER_URL}/project`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(body),
    credentials: "include",
  });

export const updateProject = (projectId: number | string, body: any) => {
  const projectIdParsed =
    typeof projectId === "string" ? parseInt(projectId, 10) : projectId;
  return fetch(`${REACT_APP_SERVER_URL}/project/${projectIdParsed}`, {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(body),
    credentials: "include",
  });
};

export const deleteProject = (projectId: number) =>
  fetch(`${REACT_APP_SERVER_URL}/project/${projectId}`, {
    method: "DELETE",
    credentials: "include",
  });

// PROJECT UPDATES
export const getProjectUpdates = (
  projectId: number,
): Promise<ProjectUpdate[]> =>
  fetch(`${REACT_APP_SERVER_URL}/project_updates/${projectId}`, {
    credentials: "include",
  }).then((response) => {
    if (!response.ok) {
      throw new Error("Failed to fetch project updates");
    }
    return response.json();
  });

interface ProjectUpdatesPayload {
  projectId: number;
  bundleUrl: string;
  checksum: string;
  projectUpdateId?: number;
}

export const makeCurrentBuild = async ({
  projectId,
  bundleUrl,
  checksum,
  projectUpdateId,
}: ProjectUpdatesPayload) => {
  const response = await fetch(
    `${REACT_APP_SERVER_URL}/project_updates/${projectId}`,
    {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        bundleUrl,
        checksum,
        projectUpdateId,
      }),
      credentials: "include",
    },
  );

  if (!response.ok) {
    throw new Error("Failed to make current build");
  }

  return response.json();
};

export const createProjectUpdate = async ({
  projectId,
  bundleUrl,
  checksum,
}: ProjectUpdatesPayload) => {
  const response = await fetch(
    `${process.env.REACT_APP_SERVER_URL}/project_updates/${projectId}`,
    {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ bundleUrl, checksum }),
      credentials: "include",
    },
  );

  if (!response.ok) {
    throw new Error("Failed to create project update");
  }

  return response.json();
};

// TRIGGER BUILD
interface TriggerBuildProps {
  projectId: number;
  url?: string;
}
export const triggerBuild = async (payload: TriggerBuildProps) => {
  const response = await fetch(`${REACT_APP_SERVER_URL}/trigger-build`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(payload),
    credentials: "include",
  });

  if (!response.ok) {
    throw new Error("Failed to trigger new build");
  }

  return response.json();
};

// LOGS
export async function getLogs(pipelineId: string) {
  const response = await fetch(
    `${REACT_APP_SERVER_URL}/circleci/logs/${pipelineId}`,
    {
      credentials: "include",
    },
  );

  if (!response.ok) {
    throw new Error("Failed to fetch logs");
  }

  return response.json();
}

// UPLOAD FOLDER
export async function uploadFolder(formData: FormData) {
  const response = await fetch(`${REACT_APP_SERVER_URL}/upload-folder`, {
    method: "POST",
    body: formData,
    credentials: "include",
  });

  if (!response.ok) {
    throw new Error("Failed to upload folder.");
  }

  return response.json();
}

// WAITLIST SIGNUP
interface WaitlistSignUpInputs {
  name: string;
  email: string;
  company: string;
  usage: string;
}

export const addToWaitlist = async (inputs: WaitlistSignUpInputs) => {
  const response = await fetch(`${REACT_APP_SERVER_URL}/waitlist`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(inputs),
    credentials: "include",
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.error || "Signup failed due to server response");
  }

  return response.json();
};

// CONTENT NODES
interface AvailableNodesResponse {
  nodes: ContentNode[];
  totalNodes: number;
}

interface GetAvailableNodesParams {
  page?: number;
  pageSize?: number;
}

export const getAvailableNodes = async ({
  page = 1,
  pageSize = 10,
}: GetAvailableNodesParams = {}): Promise<AvailableNodesResponse> => {
  const response = await fetch(`${REACT_APP_SERVER_URL}/nodes/available`, {
    credentials: "include",
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.error || "Failed to fetch available nodes");
  }

  const data = await response.json();

  // Implement client-side pagination
  const startIndex = (page - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const paginatedNodes = data.nodes.slice(startIndex, endIndex);

  return {
    nodes: paginatedNodes,
    totalNodes: data.totalNodes,
  };
};

// NODE RESERVATIONS
interface ReserveNodesPayload {
  projectId: number;
  nodeIds: string[];
  spot?: boolean;
  renew?: boolean;
}

export const reserveNodes = async ({
  projectId,
  nodeIds,
  spot = true,
  renew = true,
}: ReserveNodesPayload) => {
  const response = await fetch(`${REACT_APP_SERVER_URL}/nodes/reservations`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ projectId, nodeIds, spot, renew }),
    credentials: "include",
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.error || "Failed to reserve nodes");
  }

  return response.json();
};

export const deleteNodeReservation = async (
  projectId: number,
  nodeId: string,
): Promise<void> => {
  try {
    const response = await fetch(
      `${REACT_APP_SERVER_URL}/nodes/reservations/${projectId}/${nodeId}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
      },
    );

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || "Error deleting node reservation");
    }

    const data = await response.json();
    console.log("Node reservation successfully deleted:", data);
  } catch (error) {
    console.error("Error deleting reservation:", error);
    throw error;
  }
};
