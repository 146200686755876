import React, { ReactElement } from "react";
import {
  ComponentWithAs,
  LinkProps,
  ButtonProps,
  Button,
} from "@chakra-ui/react";

const redirectToGithub = async () => {
  window.location.href = process.env.REACT_APP_INSTALLATION_URL as string;
};

// This component is generic enough to be used in any part of the app
// It can be either a Button or a Link and when clicked it will redirect to the Github installation page
// Component and props are passed as arguments to allow for customization
export default function GithubAuthButton({
  Component = Button,
  props = {},
  displayText = "Connect Github",
}: {
  Component?: ComponentWithAs<"a">;
  props?: LinkProps | ButtonProps;
  displayText?: string;
}): ReactElement {
  return (
    <Component {...props} onClick={redirectToGithub}>
      {displayText}
    </Component>
  );
}
