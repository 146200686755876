import { Text, Link } from "@chakra-ui/react";
import TopAlertMessage from "../TopAlertBanner";

const validEnvironments = ["testnet-sepolia-staging", "testnet-sepolia"];

function StagingWarningBanner() {
  if (!validEnvironments.includes(process.env.REACT_APP_NODE_ENV || "")) {
    return null;
  }

  return (
    <TopAlertMessage
      id={"staging-node-reset-warning"}
      content={setContent()}
      expirationDays={7}
    />
  );
}

function setContent() {
  switch (process.env.REACT_APP_NODE_ENV) {
    case "testnet-sepolia-staging":
      return (
        <Text color="white">
          Welcome to the EarthFast Staging Environment! Please note - projects
          will be reset every Saturday evening.
        </Text>
      );
    default:
    case "testnet-sepolia":
      return (
        <Text color="white">
          This is an Alpha environment, please do not serve live traffic through
          these sites. For more information email{" "}
          <Link href="mailto:contact@earthfast.com">
            <Text textDecoration="underline" display="inline" color="white">
              contact@earthfast.com
            </Text>
          </Link>
        </Text>
      );
  }
}

export default StagingWarningBanner;
