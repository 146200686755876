// import forge from "node-forge";
import { parseISO, compareDesc } from "date-fns";

// const publicKeyBase64 = process.env.REACT_APP_CIRCLECI_PUBLIC_KEY_BASE64;
// if (!publicKeyBase64) {
//   throw new Error("Public key is not defined in environment variables");
// }
// const publicKeyPem = atob(publicKeyBase64);
// const publicKey = forge.pki.publicKeyFromPem(publicKeyPem);

export function isProjectInfoUrl(
  info: ProjectInfoUrl | ProjectInfoGithub | ProjectInfoManual,
): info is ProjectInfoUrl {
  return (info as ProjectInfoUrl).url !== undefined;
}

export function isProjectInfoGithub(
  info: ProjectInfoUrl | ProjectInfoGithub | ProjectInfoManual,
): info is ProjectInfoGithub {
  return (info as ProjectInfoGithub).repositoryId !== undefined;
}

export const encryptWithPublicKey = (data: any) => {
  // const encrypted = publicKey.encrypt(data, "RSA-OAEP", {
  //   md: forge.md.sha256.create(),
  //   mgf1: { md: forge.md.sha1.create() },
  // });
  // return forge.util.encode64(encrypted);
  return data;
};

export function getRecentPublishTransaction(transactions?: Transaction[]) {
  if (!transactions?.length) return;
  const publishTransactions = transactions.filter(
    (transaction) => transaction.transactionType === "publishProject",
  );

  publishTransactions.sort((a, b) =>
    compareDesc(parseISO(a.timestamp), parseISO(b.timestamp)),
  );

  return publishTransactions[0];
}

export function capitalize(text: string) {
  let sentences = text.split(/([.!?]\s+)/);
  sentences = sentences.map((sentence) => {
    if (sentence.trim() === "") return sentence;
    return sentence.trim().charAt(0).toUpperCase() + sentence.trim().slice(1);
  });
  return sentences.join("");
}

export function setStatusColor(status: string) {
  switch (status) {
    case "pending":
      return "orange";
    case "failure":
      return "red";
    default:
      return "green";
  }
}

const setFavicon = (isDarkMode: boolean) => {
  const favicon = document.getElementById("favicon") as HTMLLinkElement;
  if (isDarkMode && favicon) {
    favicon.href = process.env.PUBLIC_URL + "/earthfast-logo-dark.ico";
  } else if (favicon) {
    favicon.href = process.env.PUBLIC_URL + "/earthfast-logo.ico";
  }
};

const updateFavicon = () => {
  const isDarkMode =
    window.matchMedia &&
    window.matchMedia("(prefers-color-scheme: dark)").matches;
  setFavicon(isDarkMode);
};

export const initFavicon = () => {
  updateFavicon();
  window
    .matchMedia("(prefers-color-scheme: dark)")
    .addEventListener("change", updateFavicon);
};

export const formatNodeId = (id: string) => {
  if (id.length > 12) {
    return `${id.slice(0, 6)}...${id.slice(-5)}`;
  }
  return id;
};
