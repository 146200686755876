import { memo } from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Text,
  Box,
  Spinner,
} from "@chakra-ui/react";
import { formatNodeId } from "../../util";
import NodeTooltip from "../NodeTooltip";

interface NodesTableProps {
  nodes: ContentNode[];
  renderAction: (node: ContentNode) => React.ReactNode;
  isLoading?: boolean;
  emptyMessage?: string;
  showProjectIds?: boolean;
}

const NodesTable = memo(
  ({
    nodes,
    renderAction,
    isLoading,
    emptyMessage = "No nodes found",
    showProjectIds = true,
  }: NodesTableProps) => (
    <Box overflowX="auto">
      <Table variant="simple" size="sm">
        <Thead>
          <Tr>
            <Th width="45%">Host</Th>
            <Th width="25%">ID</Th>
            <Th width="20%">Region</Th>
            <Th width="10%"></Th>
          </Tr>
        </Thead>
        <Tbody minH="200px" display="table-row-group">
          {isLoading ? (
            <Tr>
              <Td
                colSpan={4}
                textAlign="center"
                height="200px"
                verticalAlign="middle"
              >
                <Spinner size="sm" mr={2} />
                Loading nodes...
              </Td>
            </Tr>
          ) : nodes.length === 0 ? (
            <Tr>
              <Td
                colSpan={4}
                textAlign="center"
                height="200px"
                verticalAlign="middle"
              >
                {emptyMessage}
              </Td>
            </Tr>
          ) : (
            nodes.map((node) => (
              <Tr key={node.id}>
                <Td width="45%">{node.host}</Td>
                <Td width="25%">
                  <NodeTooltip node={node} showProjectIds={showProjectIds}>
                    <Text
                      overflow="hidden"
                      textOverflow="ellipsis"
                      whiteSpace="nowrap"
                      maxW="350px"
                      display="block"
                    >
                      {formatNodeId(node.id)}
                    </Text>
                  </NodeTooltip>
                </Td>
                <Td width="20%">{node.region}</Td>
                <Td width="10%" textAlign="center">
                  {renderAction(node)}
                </Td>
              </Tr>
            ))
          )}
        </Tbody>
      </Table>
    </Box>
  ),
);

export default NodesTable;
