import { useState, useEffect, useRef } from "react";
import NodesTab from "./NodesTab";
import {
  getAvailableNodes,
  reserveNodes,
  deleteNodeReservation,
} from "../../../api";

interface NodesTabContainerProps {
  nodes?: ContentNode[];
  projectId?: number;
}

const nodesPerPage = 5;
const MAX_NODES_PER_PROJECT = 2;

export default function NodesTabContainer({
  nodes: initialNodes,
  projectId,
}: NodesTabContainerProps) {
  const [nodes, setNodes] = useState<ContentNode[]>(initialNodes || []);
  const [availableNodes, setAvailableNodes] = useState<ContentNode[]>([]);
  const [selectedAvailableNodes, setSelectedAvailableNodes] = useState<
    string[]
  >([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalNodes, setTotalNodes] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isReserving, setIsReserving] = useState(false);
  const [deletingNodeId, setDeletingNodeId] = useState<string | null>(null);

  const exceedLimit = useRef(
    localStorage.getItem("EXCEED_MAX_NODES_LIMIT") === "true",
  );

  useEffect(() => {
    const loadAvailableNodes = async () => {
      setIsLoading(true);
      try {
        const response = await getAvailableNodes({
          page: currentPage,
          pageSize: nodesPerPage,
        });
        setAvailableNodes(response.nodes);
        setTotalNodes(response.totalNodes);
      } catch (error) {
        console.error("Error loading available nodes:", error);
      } finally {
        setIsLoading(false);
      }
    };

    loadAvailableNodes();
  }, [currentPage]);

  const handleAddSelectedNodes = async () => {
    if (!projectId) return;

    if (
      !exceedLimit.current &&
      nodes.length + selectedAvailableNodes.length > MAX_NODES_PER_PROJECT
    ) {
      alert(
        `You can only have ${MAX_NODES_PER_PROJECT} nodes per project. Set EXCEED_MAX_NODES_LIMIT in localStorage to bypass this limit.`,
      );
      return;
    }

    setIsReserving(true);
    try {
      await reserveNodes({
        projectId,
        nodeIds: selectedAvailableNodes,
        spot: true,
        renew: true,
      });

      const nodesToAdd = availableNodes.filter((node) =>
        selectedAvailableNodes.includes(node.id),
      );

      setNodes([...nodes, ...nodesToAdd]);
      setSelectedAvailableNodes([]);
      setAvailableNodes(
        availableNodes.filter(
          (node) => !selectedAvailableNodes.includes(node.id),
        ),
      );
      setTotalNodes((prev) => prev - nodesToAdd.length);
    } catch (error) {
      console.error("Error reserving nodes:", error);
    } finally {
      setIsReserving(false);
    }
  };

  const handleRemoveNode = async (nodeId: string) => {
    if (!projectId) return;

    setDeletingNodeId(nodeId);
    try {
      await deleteNodeReservation(projectId, nodeId);
      setNodes(nodes.filter((node) => node.id !== nodeId));
      setTotalNodes((prev) => prev + 1);
    } catch (error) {
      console.error("Error deleting node:", error);
    } finally {
      setDeletingNodeId(null);
    }
  };

  const handleNodeSelection = (nodeId: string, isSelected: boolean) => {
    if (isSelected) {
      setSelectedAvailableNodes([...selectedAvailableNodes, nodeId]);
    } else {
      setSelectedAvailableNodes(
        selectedAvailableNodes.filter((id) => id !== nodeId),
      );
    }
  };

  const filteredNodes = availableNodes.filter(
    (node) =>
      node.host.toLowerCase().includes(searchTerm.toLowerCase()) ||
      node.id.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  const totalPages = Math.ceil(totalNodes / nodesPerPage);

  return (
    <NodesTab
      nodes={nodes}
      availableNodes={filteredNodes}
      selectedAvailableNodes={selectedAvailableNodes}
      searchTerm={searchTerm}
      currentPage={currentPage}
      totalPages={totalPages}
      nodesPerPage={nodesPerPage}
      totalNodes={totalNodes}
      onAddNodes={handleAddSelectedNodes}
      onRemoveNode={handleRemoveNode}
      onSearchChange={setSearchTerm}
      onPageChange={setCurrentPage}
      onNodeSelection={handleNodeSelection}
      isLoading={isLoading}
      isReserving={isReserving}
      deletingNodeId={deletingNodeId}
      maxNodesLimit={MAX_NODES_PER_PROJECT}
      currentNodesCount={nodes.length}
      exceedLimit={exceedLimit.current}
    />
  );
}
