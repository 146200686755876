import { Tooltip, VStack, Text, TooltipProps } from "@chakra-ui/react";

interface NodeTooltipProps extends Omit<TooltipProps, "children"> {
  node: ContentNode;
  children: React.ReactElement;
  showProjectIds?: boolean;
}

function NodeTooltip({
  node,
  children,
  showProjectIds = true,
  ...tooltipProps
}: NodeTooltipProps) {
  return (
    <Tooltip
      label={
        <VStack
          align="start"
          spacing={1}
          p={2}
          w="450px"
          bg="gray.800"
          color="white"
          borderRadius="md"
        >
          <Text>
            <Text as="strong" color="blue.200">
              ID:
            </Text>{" "}
            <Text as="span" wordBreak="break-all" color="gray.200">
              {node.id}
            </Text>
          </Text>
          <Text>
            <Text as="strong" color="blue.200">
              Host:
            </Text>{" "}
            <Text as="span" wordBreak="break-word" color="gray.200">
              {node.host}
            </Text>
          </Text>
          <Text>
            <Text as="strong" color="blue.200">
              Region:
            </Text>{" "}
            <Text as="span" color="gray.200">
              {node.region}
            </Text>
          </Text>
          {node.operatorId && (
            <Text>
              <Text as="strong" color="blue.200">
                Operator ID:
              </Text>{" "}
              <Text as="span" wordBreak="break-all" color="gray.200">
                {node.operatorId}
              </Text>
            </Text>
          )}
          {node.prices && node.prices.length > 0 && (
            <Text>
              <Text as="strong" color="blue.200">
                Prices:
              </Text>{" "}
              <Text as="span" color="gray.200">
                {node.prices.join(", ")}
              </Text>
            </Text>
          )}
          {showProjectIds && node.projectIds && !!node.projectIds.length && (
            <VStack align="start" spacing={0} w="full">
              <Text as="strong" color="blue.200">
                Project IDs:
              </Text>
              {node.projectIds.map((projectId, index) => (
                <Text
                  key={index}
                  as="span"
                  wordBreak="break-all"
                  color="gray.200"
                  pl={4}
                  fontSize="sm"
                >
                  • {projectId}
                </Text>
              ))}
            </VStack>
          )}
          {node.disabled && (
            <Text>
              <Text as="strong" color="blue.200">
                Status:
              </Text>{" "}
              <Text as="span" color="red.300">
                Disabled
              </Text>
            </Text>
          )}
        </VStack>
      }
      hasArrow
      placement="top"
      openDelay={200}
      bg="gray.800"
      {...tooltipProps}
    >
      {children}
    </Tooltip>
  );
}

export default NodeTooltip;
