import { Heading, Button, useToast } from "@chakra-ui/react";
import { Input, FormControl, FormLabel, VStack, Text } from "@chakra-ui/react";
import { useState } from "react";

interface CustomDomainProps {
  hostedProjectUrl?: string;
}

function CustomDomain({ hostedProjectUrl }: CustomDomainProps) {
  const toast = useToast();
  const [customDomain, setCustomDomain] = useState("");

  const handleAddDomain = () => {
    // Here you would implement the logic to add the custom domain
    // This could involve an API call to your backend
    console.log("Adding domain:", customDomain);
    toast({
      title: "Domain added",
      description: "Your custom domain has been successfully added.",
      status: "success",
      duration: 5000,
      isClosable: true,
    });
    setCustomDomain("");
  };

  return (
    <VStack spacing={4} align="stretch">
      <Heading size="md">Custom Domain</Heading>
      <Text>Add a custom domain to your hosted project.</Text>
      <FormControl>
        <FormLabel>Domain</FormLabel>
        <Input
          placeholder="example.com"
          value={customDomain}
          onChange={(e) => setCustomDomain(e.target.value)}
        />
      </FormControl>
      <Button
        colorScheme="blue"
        onClick={handleAddDomain}
        isDisabled={!customDomain}
      >
        Add Domain
      </Button>
      <Text fontSize="sm">
        Note: After adding your domain, you'll need to configure your DNS
        settings. Point your domain's CNAME record to{" "}
        {hostedProjectUrl || "your-project-url.example.com"}.
      </Text>
    </VStack>
  );
}

export default CustomDomain;
