import { useEffect, useState, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Logs from "./Logs";
import { useToast } from "@chakra-ui/react";
import { getLogs } from "../../api";
import { Log } from "./Logs.types";

function LogsContainer() {
  const { pipelineId } = useParams<{ pipelineId: string }>();
  const [logs, setLogs] = useState<Log[]>([]);
  const [loading, setLoading] = useState(true);
  const [polling, setPolling] = useState(true);
  const toast = useToast();
  const navigate = useNavigate();

  const fetchLogs = useCallback(async () => {
    if (!pipelineId) return;
    try {
      const data = await getLogs(pipelineId);
      setLogs(data);
      const allJobsStopped = data.every((log: Log) => log.job.stopped_at);
      if (allJobsStopped) {
        setPolling(false);
      }
    } catch (error) {
      console.error("Error fetching logs:", error);
      toast({
        title: "Error fetching logs",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      setPolling(false);
    } finally {
      setLoading(false);
    }
  }, [pipelineId, toast]);

  useEffect(() => {
    fetchLogs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pipelineId]);

  useEffect(() => {
    let intervalId: NodeJS.Timeout;

    if (polling) {
      intervalId = setInterval(() => {
        fetchLogs();
      }, 10000);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [polling, fetchLogs]);

  function goBack() {
    navigate(-1);
  }

  return <Logs goBack={goBack} logs={logs} loading={loading} />;
}

export default LogsContainer;
