import { Box, Flex, Text, Spacer, Button } from "@chakra-ui/react";
import { useLoaderData } from "react-router-dom";
import { ArrowBackIcon } from "@chakra-ui/icons";

export default function ViewSite() {
  const data = useLoaderData() as { url: string };
  // const [repoInfo, setrepoInfo] = useState({} as object);
  const url = "https://" + data.url;
  console.log("url", url);

  return (
    <Box flex="1" width="full" border="0" marginTop="-20px;">
      <Flex
        backgroundColor="white;"
        height="50px"
        minWidth="max-content"
        alignItems="center"
        gap="2"
        borderBottom={"1px solid #9f9f9f;"}
      >
        <Button
          colorScheme="blue"
          variant="outline"
          size="sm"
          marginLeft="20px"
          leftIcon={<ArrowBackIcon />}
          onClick={() => {
            window.location.href = "/";
          }}
        >
          Back to Dashboard
        </Button>
        {/* <Button
          colorScheme="blue"
          variant="outline"
          size="sm"
          marginLeft="20px"
          onClick={() => {
            window.location.href = "/";
          }}
        >
          View on Github
        </Button> */}
        {/* <Button
          colorScheme="blue"
          variant="outline"
          size="sm"
          marginLeft="20px"
          onClick={() => {
            window.location.href = "/";
          }}
        >
          View Build Assets
        </Button> */}
        <Spacer />
        <Text fontSize="lg" fontWeight="500" color="black" paddingX="30">
          🟢 {data.url}
        </Text>
      </Flex>
      <iframe
        src={url}
        style={{ flex: "1", width: "100%", height: "100%" }}
        title="Unique Title"
      ></iframe>
    </Box>
  );
}
