import { Link } from "react-router-dom";
import {
  Box,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Heading,
  Button,
  Flex,
  Badge,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
} from "@chakra-ui/react";
import { FiUpload, FiEye, FiList, FiTrash } from "react-icons/fi";
import { ArrowBackIcon } from "@chakra-ui/icons";
import ProjectInfoDisplay from "../../components/ProjectInfoDisplay";
import ProjectTransactions from "../../components/ProjectTransactions";
import { setStatusColor } from "../../util";
import SettingsTab from "./SettingsTab";
import NodesTab from "./NodesTab";
import CustomDomainTab from "./CustomDomainTab";
import Loading from "./Loading";

interface ProjectViewProps {
  createdTransaction?: Transaction;
  lastBuildTransaction?: Transaction;
  isBuilding: boolean;
  projectData: ProjectObject | null;
  isDeleting: boolean;
  isDeleteAlertOpen: boolean;
  isLoadingProject: boolean;
  cancelRef: React.RefObject<HTMLButtonElement>;
  tabIndex: number;
  handleDeleteClick: () => void;
  handleDeleteConfirm: () => void;
  onCloseConfirm: () => void;
  onGoBack: () => void;
  setTabIndex: (index: number) => void;
}

export default function ProjectView({
  createdTransaction,
  lastBuildTransaction,
  isBuilding,
  projectData,
  isDeleting,
  isDeleteAlertOpen,
  cancelRef,
  isLoadingProject,
  tabIndex,
  handleDeleteClick,
  handleDeleteConfirm,
  onCloseConfirm,
  onGoBack,
  setTabIndex,
}: ProjectViewProps) {
  return (
    <Box
      px={5}
      py={12}
      borderWidth="1px"
      borderRadius="lg"
      width="80%"
      marginX="auto"
      bg="white"
      position="relative"
    >
      <Button
        leftIcon={<ArrowBackIcon />}
        variant="ghost"
        onClick={onGoBack}
        position="absolute"
        top={4}
        left={4}
      >
        Back
      </Button>
      <Box width={{ base: "93%", md: "80%" }} minWidth="300px" marginX="auto">
        {isLoadingProject ? (
          <Loading />
        ) : (
          <>
            <Flex
              justifyContent="space-between"
              alignItems="flex-start"
              mb={6}
              mt={6}
            >
              <div>
                <Heading
                  fontSize={32}
                  as="h3"
                  fontWeight="semibold"
                  textAlign="left"
                >
                  {projectData?.name}
                </Heading>
                {projectData && (
                  <>
                    <ProjectInfoDisplay project={projectData} fontSize="md" />
                    {!isBuilding && (
                      <ProjectTransactions
                        createdTransaction={createdTransaction}
                        lastBuildTransaction={lastBuildTransaction}
                        fontSize="md"
                      />
                    )}
                    {projectData?.buildStatus && (
                      <Badge
                        bg={`${setStatusColor(projectData.buildStatus)}.50`}
                        color={`${setStatusColor(projectData.buildStatus)}.600`}
                        textTransform="capitalize"
                        px={2}
                        rounded="400px"
                        fontWeight={500}
                        borderColor={`${setStatusColor(projectData.buildStatus)}.300`}
                        borderWidth="1px"
                        mt={2}
                      >
                        <Flex alignItems="center" gap={1}>
                          <Flex
                            width="6px"
                            height="6px"
                            borderRadius="50%"
                            bg={`${setStatusColor(projectData.buildStatus)}.600`}
                          />
                          {projectData.buildStatus === "pending"
                            ? "Building"
                            : projectData.buildStatus}
                        </Flex>
                      </Badge>
                    )}
                  </>
                )}
              </div>
              {projectData && (
                <Flex>
                  {projectData.projectType === "upload" && (
                    <Button
                      as={Link}
                      to={`/projects/${projectData.id}/deploy`}
                      leftIcon={<FiUpload />}
                      colorScheme="gray"
                      variant="outline"
                      size="sm"
                      mr={2}
                    >
                      Upload
                    </Button>
                  )}
                  <Button
                    leftIcon={<FiList />}
                    colorScheme="gray"
                    as={Link}
                    to={`/project_updates/${projectData.id}`}
                    variant="outline"
                    size="sm"
                    mr={2}
                  >
                    Builds
                  </Button>
                  {projectData.hasSuccessfulBuild &&
                    projectData.hostedProjectUrl && (
                      <Button
                        leftIcon={<FiEye />}
                        colorScheme="gray"
                        as="a"
                        target="_blank"
                        href={`https://${projectData.hostedProjectUrl}`}
                        variant="outline"
                        size="sm"
                        mr={2}
                      >
                        View site
                      </Button>
                    )}
                  <Button
                    leftIcon={<FiTrash />}
                    colorScheme="red"
                    variant="outline"
                    size="sm"
                    onClick={handleDeleteClick}
                    isLoading={isDeleting}
                  >
                    Delete
                  </Button>
                </Flex>
              )}
            </Flex>
            <Tabs index={tabIndex} onChange={(index) => setTabIndex(index)}>
              <TabList>
                <Tab>Settings</Tab>
                <Tab>Nodes</Tab>
                {/* <Tab>Custom Domain</Tab> */}
              </TabList>
              <TabPanels>
                <TabPanel>
                  <SettingsTab projectData={projectData} />
                </TabPanel>
                <TabPanel>
                  <NodesTab
                    nodes={projectData?.reservedNodes}
                    projectId={projectData?.id}
                  />
                </TabPanel>
                <TabPanel>
                  <CustomDomainTab
                    hostedProjectUrl={projectData?.hostedProjectUrl}
                  />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </>
        )}
      </Box>

      {/* Keep the existing AlertDialog for project deletion */}
      <AlertDialog
        isOpen={isDeleteAlertOpen}
        leastDestructiveRef={cancelRef}
        onClose={onCloseConfirm}
        isCentered
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Project
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure you want to delete this project? This action cannot
              be undone.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onCloseConfirm}>
                Cancel
              </Button>
              <Button colorScheme="red" onClick={handleDeleteConfirm} ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  );
}
