import { memo } from "react";
import { Text, Box, VStack, HStack, Heading, Badge } from "@chakra-ui/react";

interface NodesPanelContainerProps {
  title: string;
  badgeCount: number;
  badgeColor?: string;
  description: string;
  headerAction?: React.ReactNode;
  children: React.ReactNode;
}

const NodesPanelContainer = memo(
  ({
    title,
    badgeCount,
    badgeColor = "gray",
    description,
    headerAction,
    children,
  }: NodesPanelContainerProps) => (
    <Box borderWidth="1px" borderRadius="lg" p={4} flex="1">
      <VStack align="stretch" spacing={2} mb={4}>
        <HStack justify="space-between">
          <Heading size="sm">
            {title}
            <Badge ml={2} colorScheme={badgeColor}>
              {badgeCount}
            </Badge>
          </Heading>
          {headerAction}
        </HStack>
        <Text fontSize="sm" color="gray.600">
          {description}
        </Text>
      </VStack>
      {children}
    </Box>
  ),
);

export default NodesPanelContainer;
