import GitHubProjects from "../../../components/ProjectConfigGithub";
import AddProjectByUrl from "../../../components/ProjectConfigUrl";
import ManualProjectUpload from "../../../components/ProjectConfigManual";

interface SettingsProps {
  projectData: ProjectObject | null;
}

export default function Settings({ projectData }: SettingsProps) {
  if (!projectData) return null;
  if (projectData.projectType === "github") {
    return <GitHubProjects isEditing={true} projectData={projectData} />;
  }
  if (projectData.projectType === "url") {
    return <AddProjectByUrl isEditing={true} projectData={projectData} />;
  }
  if (projectData.projectType === "upload") {
    return <ManualProjectUpload isEditing={true} projectData={projectData} />;
  }
  return null;
}
