import { IoMdCheckmark } from "react-icons/io";
import { MdAccessTime } from "react-icons/md";
import { RxCross1 } from "react-icons/rx";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { tomorrow } from "react-syntax-highlighter/dist/esm/styles/prism";

import {
  VStack,
  Box,
  Text,
  Button,
  Flex,
  Heading,
  Stack,
  Skeleton,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from "@chakra-ui/react";
import { capitalize } from "../../util";
import { format } from "date-fns";
import { LogsProps, Step } from "./Logs.types";

function Logs({ goBack, loading, logs }: LogsProps) {
  return (
    <VStack mt={10} width="100%">
      <Flex justifyContent="space-between" width="80%" mb={6}>
        <Heading fontSize={32} fontWeight="semibold">
          Logs
        </Heading>
        <Button onClick={goBack} variant="outline" fontWeight={500}>
          Back
        </Button>
      </Flex>
      {loading ? (
        <Box w={{ base: "90%", md: "80%" }}>
          <Stack>
            <Skeleton height="70px" borderRadius="lg" />
            <Skeleton height="70px" borderRadius="lg" />
            <Skeleton height="70px" borderRadius="lg" />
            <Skeleton height="70px" borderRadius="lg" />
          </Stack>
        </Box>
      ) : (
        <Accordion width="80%" allowMultiple defaultIndex={[0]}>
          {logs.map((log, index) => (
            <AccordionItem
              key={`${log.job.name}-${index}`}
              mb={3}
              bg="white"
              width="100%"
              borderWidth={1}
              borderRadius={6}
            >
              <AccordionButton py={4} justifyContent="space-between">
                <Flex alignItems="center" gap={2}>
                  <AccordionIcon />
                  <Text fontWeight="bold" color="secondary" fontSize={20}>
                    {capitalize(log.job.name)}
                  </Text>
                  <Flex
                    bg={
                      log.job.stopped_at
                        ? log.job.status === "failed"
                          ? "red"
                          : "green"
                        : "orange"
                    }
                    borderRadius={12}
                    py={1}
                    px={2}
                  >
                    <Flex
                      as="span"
                      alignItems="center"
                      color="white"
                      fontSize="14px"
                      gap={1}
                    >
                      {!log.job.stopped_at ? (
                        <>
                          <MdAccessTime color="white" fontSize={16} />
                          Running
                        </>
                      ) : log.job.status === "failed" ? (
                        <>
                          <RxCross1 color="white" fontSize={16} />
                          Failed
                        </>
                      ) : (
                        <>
                          <IoMdCheckmark color="white" fontSize={16} />
                          Success
                        </>
                      )}
                    </Flex>
                  </Flex>
                </Flex>
                <Flex gap={1} alignItems="center" color="gray.500">
                  <MdAccessTime />
                  <Text color="gray.500" fontSize="smaller">
                    {format(new Date(log.job.started_at), "MMM d, h:mma")}
                  </Text>
                  -
                  <Text color="gray.500" fontSize="smaller">
                    {log.job.stopped_at
                      ? format(new Date(log.job.stopped_at), "MMM d, h:mma")
                      : "Running"}
                  </Text>
                </Flex>
              </AccordionButton>
              <AccordionPanel pb={4}>
                <Accordion width="100%" allowMultiple>
                  {log.steps.map((step) => (
                    <StepRow key={`${step.name}-${index}`} {...step} />
                  ))}
                </Accordion>
              </AccordionPanel>
            </AccordionItem>
          ))}
        </Accordion>
      )}
    </VStack>
  );
}

const parseOutput = (output: string) => {
  try {
    const parsedOutput = JSON.parse(output);
    return parsedOutput.map((entry: any) => entry.message).join("\n");
  } catch (error) {
    console.error("Error parsing output:", error);
    return "Error parsing output";
  }
};

function StepRow({ name, actions }: Step) {
  const isRunning = !actions[0]?.end_time;
  return (
    <AccordionItem
      bg="white"
      width="100%"
      borderWidth={1}
      borderRadius={6}
      mb={3}
    >
      <AccordionButton
        py={4}
        justifyContent="space-between"
        onClick={(e) => isRunning && e.preventDefault()}
        cursor={isRunning ? "not-allowed" : "pointer"}
      >
        <Flex alignItems="center" gap={2}>
          <AccordionIcon opacity={isRunning ? 0 : 1} />
          <Flex
            bg={
              isRunning
                ? "orange"
                : actions[0]?.status === "failed"
                  ? "red"
                  : "green"
            }
            borderRadius={12}
            py={1}
            px={2}
          >
            {isRunning ? (
              <MdAccessTime color="white" fontSize={16} />
            ) : actions[0]?.status === "failed" ? (
              <RxCross1 color="white" fontSize={16} />
            ) : (
              <IoMdCheckmark color="white" fontSize={16} />
            )}
          </Flex>
          <Text fontWeight="semibold" color="secondary" fontSize={16}>
            {capitalize(name)}
          </Text>
        </Flex>
        <Flex gap={1} alignItems="center" color="gray.500">
          <MdAccessTime />
          <Text color="gray.500" fontSize="smaller">
            {format(new Date(actions[0]?.start_time), "MMM d, h:mma")}
          </Text>
          -
          <Text color="gray.500" fontSize="smaller">
            {isRunning
              ? "Running"
              : format(new Date(actions[0]?.end_time), "MMM d, h:mma")}
          </Text>
        </Flex>
      </AccordionButton>
      <AccordionPanel pb={4}>
        <SyntaxHighlighter language="bash" style={tomorrow} wrapLines={true}>
          {parseOutput(actions[0]?.output)}
        </SyntaxHighlighter>
      </AccordionPanel>
    </AccordionItem>
  );
}

export default Logs;
