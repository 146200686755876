import React from "react";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Center, Text } from "@chakra-ui/react";

type QueryParams = {
  code: string;
  state?: string;
  installationId?: string;
};

function useQuery() {
  const params = new URLSearchParams(useLocation().search);
  const paramsObj: QueryParams = { code: "", state: "", installationId: "" };
  if (params.has("code")) {
    paramsObj.code = params.get("code") as string;
  }
  if (params.has("state")) {
    paramsObj.state = params.get("state") as string;
  }
  if (params.has("installation_id")) {
    paramsObj.installationId = params.get("installation_id") as string;
  }

  return paramsObj;
}

function Callback() {
  const query = useQuery();
  const [message, setMessage] = useState("Authorizing...");

  useEffect(() => {
    // This function will only be called once when the component mounts
    handleAuthorization();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // The empty dependency array ensures this effect runs only once

  const handleAuthorization = async () => {
    if (query.code && query.installationId) {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/github/callback?code=${query.code}&installationId=${query.installationId}`,
          { credentials: "include" },
        );

        const data = await response.json();

        if (response.ok) {
          console.log("User created");
          window.location.href = "/projects/create";
        } else {
          console.error(
            "Authorization failed:",
            data.error_description || data.message,
          );
          setMessage(
            `Authorization failed: ${data.error_description || data.message}. Please try again or contact support at contact@earthfast.com`,
          );
        }
      } catch (error) {
        console.error("Error authorizing Github app:", error);
        setMessage(
          "There was an error authorizing the Github app. Please try again or contact support at contact@earthfast.com",
        );
      }
    }
  };

  return (
    <div>
      <Text as="h1" fontSize="2xl" fontWeight="bold">
        <Center>{message}</Center>
      </Text>
    </div>
  );
}

export default Callback;
