import {
  Center,
  Divider,
  Flex,
  Image,
  Link,
  Text,
  Heading,
  Tooltip,
} from "@chakra-ui/react";
import { FaUser } from "react-icons/fa";

import { useUser } from "../../context/UserContext";
import { IoMdLogOut } from "react-icons/io";

function Header({ logoutFn }: { logoutFn: () => void }) {
  const { user } = useUser();
  const loggedIn = user && user.userId;

  return (
    <Flex
      backgroundColor="white"
      minWidth="max-content"
      alignItems="center"
      gap="4"
      paddingX="20px"
      paddingY="16px"
      marginBottom="20px"
      as="header"
      justifyContent="space-between"
      borderBottom="1px solid rgba(0, 0, 0, 0.12)"
      flexDirection={{ md: "row", base: "column" }}
    >
      <Flex alignItems="center" as="nav">
        <Flex as={Link} href="/" alignItems="center">
          <Image
            src="/earthfast-logo.svg"
            height="24px"
            verticalAlign="center"
          />
          <Heading
            verticalAlign={"center"}
            textDecor="none"
            fontSize="lg"
            fontWeight="600"
            marginLeft="10px"
            as="h1"
          >
            EarthFast
          </Heading>
        </Flex>
        {!!loggedIn && (
          <>
            <Divider
              orientation="vertical"
              height="25px"
              marginX="12px"
              opacity={1}
              bg="rgba(0, 0, 0, 0.24)"
              width="1px"
            />
            <Link
              textDecor="none"
              fontSize="md"
              fontWeight="400"
              href="/projects"
            >
              Projects
            </Link>
          </>
        )}
      </Flex>
      <Flex alignItems="center">
        {loggedIn ? (
          <Flex
            border="1px solid rgba(0, 0, 0, 0.16)"
            py="4px"
            pl="4px"
            pr="14px"
            borderRadius="100px"
          >
            {!!user.github?.user?.avatar_url ? (
              <Image
                src={user.github?.user?.avatar_url}
                borderRadius="50%"
                height="30px"
                mr="5px"
                border="1px solid rgba(0, 0, 0, 0.16)"
              />
            ) : (
              <Flex
                width="30px"
                height="30px"
                rounded="50%"
                bg="gray.300"
                alignItems="center"
                justifyContent="center"
                mr="5px"
              >
                <FaUser size={14} color="white" />
              </Flex>
            )}
            <Center>
              <Tooltip
                label={user.github?.user?.login ? user.email : undefined}
                hasArrow
              >
                <Text fontSize="sm" color="#01131F">
                  {user.github?.user?.login
                    ? `@${user.github?.user?.login}`
                    : user.email}
                </Text>
              </Tooltip>
              <Divider
                orientation="vertical"
                height="20px"
                marginX="12px"
                bg="rgba(0, 0, 0, 0.24)"
                width="1px"
              />
              <Link onClick={logoutFn}>
                <IoMdLogOut />
              </Link>
            </Center>
          </Flex>
        ) : (
          <>
            <Link textDecor="none" fontSize="md" fontWeight="500" href="/login">
              Login
            </Link>
            <Divider
              orientation="vertical"
              height="20px"
              marginX="12px"
              opacity={1}
            />
            <Link
              textDecor="none"
              fontSize="md"
              fontWeight="500"
              href="/signup"
              color="primary"
            >
              Sign Up
            </Link>
          </>
        )}
      </Flex>
    </Flex>
  );
}

export default Header;
